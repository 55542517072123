@import "~@lysaab/ui-2/components/styling/_variables";

.company-signup-done {
  h2:first-child {
    margin: 20px 0 60px 0;
  }

  .card p {
    margin: 0 0 40px 0;
  }

  .lysa-ui-button.button-primary + .lysa-ui-button.block {
    margin: 10px 0 0 0;
  }
}
