@import "~@lysaab/ui-2/components/styling/_variables";

.docs-page {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .as-link {
    color: $color-primary-enabled;
    transition: color 300ms ease;
    text-decoration: underline;
    border: 0;
    background-color: transparent;
    cursor: pointer;
    font-size: inherit;

    &:visited {
      color: $color-primary-enabled;
    }

    &:active,
    &:hover,
    &:focus {
      color: $color-primary-hover;
    }
  }
}
