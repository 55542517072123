@import "~@lysaab/ui-2/components/styling/_variables";
@import "~@lysaab/ui-2/components/styling/_media";

.company-selection {
  .company-list {
    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
    }

    li {
      margin: 0;
      padding: 0;
    }

    .manual-selection {
      margin-bottom: 20px;

      .flex-container p {
        display: none;
      }

      @include landscape {
        p {
          display: none;
        }

        .flex-container p {
          display: block;
        }
      }

      .lysa-ui-input-group {
        margin-bottom: 0;

        label {
          position: absolute;
          width: 1px;
          height: 1px;
          overflow: hidden;
        }

        @include tablet {
          label {
            position: inherit;
            width: inherit;
            height: inherit;
            overflow: inherit;
          }
        }
      }
    }

    .flex-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .company-list-item {
      padding: 15px 22px;

      .lysa-ui-snackbar {
        margin: 8px 0 0 0;
        text-align: initial;
        display: block;
      }

      & + .company-list-item {
        margin-top: 16px;
      }

      cursor: pointer;

      &.disabled-company {
        .company-name {
          color: $color-text;
        }

        .company-id {
          color: $color-red;
        }

        &.clicked-company {
          cursor: initial;
        }
      }

      .company-name {
        font-weight: bold;
        text-decoration: underline;
        color: $color-blue;
      }

      .company-id {
        color: grey;
        font-size: 12px;
      }

      .select-button {
        margin-left: 15px;
        width: auto;
        min-width: 60px;
      }
    }

    span {
      display: block;
    }
  }
}
