@import "~@lysaab/ui-2/components/styling/_variables";

.pep-card {
  position: relative;

  &.done-validating {
    * {
      color: $color-mediumgray;
    }

    .lysa-ui-radio input[type="radio"]:checked + label .box {
      border-color: $color-gray;
    }
  }

  &.is-validating {
    .lysa-ui-form {
      transition: opacity $timer-fast;
      opacity: 0.1;
      pointer-events: none;
    }

    .lysa-ui-radio input[type="radio"]:checked + label .box {
      border-color: $color-black;
    }
  }

  .lysa-ui-spinner {
    max-width: 180px;
  }

  .pep-card-validating {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 60px;

    p {
      margin: -8px 0 0 0;
      text-align: center;

      span {
        background-color: $color-white;
        display: inline-block;
        padding: 2px;
        border-radius: 5px;
        box-shadow: 0 0 10px 10px $color-white;
      }
    }
  }

  .pep-card-done-validating {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    p {
      margin: 0;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      color: $color-text;
      font-size: 1.2em;
    }
  }
}
