@import "~@lysaab/ui-2/components/styling/_variables";
@import "~@lysaab/ui-2/components/styling/media";

.company-signup-beneficial-owners {
  .lysa-ui-snackbar p {
    margin: 0;
  }

  table {
    margin-bottom: 24px;
  }

  th {
    text-align: left;
    vertical-align: top;
    padding-right: 10px;
    min-width: 150px;
  }

  td {
    vertical-align: top;

    ul,
    li {
      margin: 0;
      padding: 0;
      list-style: none;
    }
  }

  .suggested-owners-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .card {
      width: 100%;
    }

    @include tablet {
      .card {
        width: calc(50% - 8px);
      }
    }
  }
}
