@import "~@lysaab/ui-2/components/styling/_variables";

.company-signup-edit-allocation-forecast {
  .graph-wrapper {
    position: relative;
    width: 100%;

    &.disabled {
      .forecast-tooltip,
      .ruler,
      .very-bad-marker,
      .bad-marker,
      .good-marker,
      .very-good-marker {
        display: none;
      }
    }
  }
}
