@import "./normalize.scss";
@import "~@lysaab/ui-2/components/styling/_layout";
@import "~@lysaab/ui-2/components/styling/_media";
@import "~@lysaab/ui-2/components/styling/_variables";

#root {
  position: relative;
}

body,
html.lysa-signup {
  background-color: $color-canvas;
}

a,
.link-button {
  border: 0;
  padding: 0;
  font-family: inherit;
  display: inline-block;
  background-color: transparent;
  will-change: color;
  transition: color $timer-fast ease;

  svg {
    will-change: fill;
    transition: fill $timer-fast ease;
  }

  &,
  &:link,
  &:visited {
    cursor: pointer;
    color: $color-primary-enabled;
    text-decoration: none;

    svg {
      fill: $color-primary-enabled;
    }

    &.disabled {
      color: $color-primary-disabled;
      cursor: default;

      &:hover,
      &:active,
      &:focus {
        color: $color-primary-disabled;
      }
    }
  }

  &:active,
  &:hover {
    color: $color-primary-hover;

    svg {
      fill: $color-primary-hover;
    }
  }

  &:focus {
    outline: 0;
  }
}

.screen-sharing-code {
  position: absolute;
  left: 10px;
  bottom: 10px;

  @include tablet {
    margin-top: -44px;
  }
}

.doc-modal {
  .modal-actions {
    display: flex;
  }
}
