.positions-list {
  @import "~@lysaab/ui-2/components/styling/_media";
  @import "~@lysaab/ui-2/components/styling/_variables";

  .summary-cell {
    margin: 4px 0 20px 0;
    padding: 4px 0;
    border-bottom: 1px solid $color-lightgray;
    font-weight: bold;
  }

  dt {
    margin: 4px 0 0 0;

    .name {
      padding-right: 0.4em;
    }

    .desc {
      color: $color-gray;
    }

    &.summary-cell {
      float: left;
      width: 70%;
    }
  }

  dd {
    padding: 0 0 4px 0;
    margin: 0;
    border-bottom: 1px solid $color-lightgray;

    &.summary-cell {
      float: right;
      width: 30%;
      text-align: right;

      &::after {
        content: " ";
        display: block;
        clear: left;
      }
    }

    .positions-currency {
      float: right;
      text-align: right;
    }
  }
}
