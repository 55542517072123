@import "~@lysaab/ui-2/components/styling/_variables";

.company-signup-add-edit-user {
  .lysa-ui-radio {
    margin-bottom: 0;
  }

  .user-types-description {
    font-size: 12px;
    font-style: italic;
    margin-bottom: 0;
  }

  .name-lookup-fail {
    margin-bottom: 24px;
    color: $color-red;

    .name-lookup-fail-error-text {
      padding-right: 8px;
    }
  }

  .lysa-ui-input-group:last-of-type {
    margin-bottom: 0;
  }
}
