@import "~@lysaab/ui-2/components/styling/_variables";

.company-signup-edit-allocation-positions {
  margin-bottom: 24px;

  .disclaimer {
    clear: both;
    color: $color-gray;
    font-size: 12px;
    line-height: 20px;
    padding-top: 10px;
  }
}
