.company-signup-identify {
  p.company-signup-ingress {
    margin: 10px 0 50px 0;
  }
  ol {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  li {
    position: relative;
    display: flex;
    padding: 4px 0;
    align-items: center;

    svg {
      margin-right: 20px;
    }

    p {
      flex: 1;
      padding: 0;
      margin: 0;
    }
  }

  .lysa-ui-checkbox-group {
    margin-bottom: 32px;
  }

  .disclaimer {
    padding: 20px 0 0 0;
    text-align: center;

    & + .disclaimer {
      padding: 0;
    }

    p {
      margin: 0;
      padding: 0;
    }
  }
}
