@import "~@lysaab/ui-2/components/styling/_variables";

.company-signup-manual-review {
  .big-icon {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    margin: 24px 0 48px 0;
  }
}
