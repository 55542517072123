@import "~@lysaab/ui-2/components/styling/_variables";
@import "~@lysaab/ui-2/components/styling/_media";

.company-signup-agreement {
  h4 svg {
    margin-right: 8px;
    margin-bottom: -2.5px;
  }

  dt {
    float: left;
    font-weight: bold;
    clear: both;
    margin-bottom: 10px;
  }

  dt.target {
    width: 100%;
  }

  dd {
    float: right;
    text-align: right;
  }

  br.clearFix {
    clear: both;
  }

  .risk-indicator-container {
    clear: both;
    width: 100%;

    .stock-number {
      text-align: left;
    }

    .blur,
    .risk-indicator {
      width: 100px;
      height: 100px;
    }

    .blur {
      border-radius: 50px;
    }
  }

  .new-tab-long-text {
    display: none;
  }

  @include landscape {
    .new-tab-long-text {
      display: inline;
    }

    .new-tab-short-text {
      display: none;
    }
  }

  table {
    width: 100%;
    padding: 0 10px;
  }

  th {
    text-align: left;
  }

  th + th,
  td + td {
    text-align: right;
  }

  .email-reminders {
    margin-top: 24px;
  }

  .admin-warning {
    font-size: 12px;
    font-style: italic;
  }

  .signing-group-description {
    margin-bottom: 24px;
  }

  .signer-names {
    padding: 0;
    margin: 0;
  }

  .signing-group + .signing-group {
    margin-top: 10px;
    padding-top: 10px;
  }

  .signing-group-header {
    padding-bottom: 10px;

    h5 {
      display: inline;
      margin-right: 0.5em;
    }

    p {
      display: inline;
      margin: 0;
      padding: 0;
    }
  }

  .signing-group-content {
    border-left: 3px solid $color-blue;
    padding-left: 8px;
  }

  .card.declaration {
    margin-top: 38px;
  }

  .disclaimer {
    padding: 20px 12px;

    button {
      background: none !important;
      border: none;
      padding: 0 !important;
      text-decoration: underline;
      cursor: pointer;
      color: $color-primary-enabled;
    }

    button:hover {
      color: $color-primary-hover;
    }

    button:active,
    button:focus {
      color: $color-primary-focus;
    }
  }

  .before-card {
    font-size: 16px;
    line-height: 1.8;
    margin: 60px 0;
  }
}
