@import "~@lysaab/ui-2/components/styling/_variables";

.company-signup-advice {
  h4 svg {
    margin-right: 8px;
    margin-bottom: -2.5px;
  }

  dt {
    float: left;
    font-weight: bold;
    clear: both;
    margin-bottom: 10px;
  }

  dt.target {
    width: 100%;
  }

  dd {
    float: right;
  }

  br.clearFix {
    clear: both;
  }

  .allocation-bar-container {
    clear: both;
    width: 100%;
  }

  .card.declaration {
    margin-top: 38px;
  }

  .box-margin {
    margin-top: 24px;
  }

  .disclaimer {
    padding: 20px 0px;
  }
}
