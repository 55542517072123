@import "~@lysaab/ui-2/components/styling/_variables";

.agreement-email {
  display: flex;

  .email-input {
    flex-grow: 1;
    margin-right: 10px;
  }

  .save-button {
    padding-top: 24px;
  }
}
