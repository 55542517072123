.company-signup-fatca-check {
  .lysa-ui-radio {
    margin: 0;
  }

  .lysa-ui-snackbar {
    margin: 24px 0 0 0;
  }

  .disclaimer {
    padding: 20px 12px;
  }
}
