@import "~@lysaab/ui-2/components/styling/_variables";

.company-signup-edit-allocation {
  .disclaimer {
    padding: 20px 12px;
  }

  .editallocation-header {
    display: flex;
    align-items: center;

    h4 {
      flex: 1;
      margin: 0;
    }

    button.reset-hidden {
      opacity: 0;
      transition: opacity $timer-standard ease-in-out;
    }
  }
}
