@use "~@lysaab/ui-2/components/styling/variables";
@use "~@lysaab/ui-2/components/styling/media";

.baked-dough-consent {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  transition: transform 300ms ease;
  transform: translate(0, 0);
  z-index: 1100;
  background-color: var(--lysa-background-info);
  padding: 20px 16px 50px;

  @include media.tablet {
    padding: 20px 166px 20px; // 166px so that it doesnt go above help
  }

  article {
    margin: 0 auto;
    max-width: variables.$desktop;
    display: flex;
    flex-wrap: wrap;

    p {
      margin: 0 0 14px 0;
    }

    button.cookie-link-button {
      color: var(--lysa-text-primary);
      text-align: left;
      display: inline;
      padding: 0;
      background-color: transparent;
      border: none;
      font: inherit;
      cursor: pointer;
      text-decoration: underline;
    }

    .col {
      padding: 0 5px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      flex-grow: 1;
    }

    @include media.tablet {
      .col {
        padding: 0 10px;
      }

      p {
        margin: 0;
      }

      flex-wrap: nowrap;
      align-items: flex-end;
    }

    @include media.desktop-plus {
      .col {
        padding: 0 20px;
      }
    }
  }
}
