.horizon-warning {
  margin: 24px 0 34px 0;

  .lysa-ui-snackbar {
    display: block;
    text-align: initial;
    margin-right: -10px;
    padding: 18px 18px 18px 48px;
  }
}
