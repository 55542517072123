@import "~@lysaab/ui-2/components/styling/_variables";

.company-signup-summary {
  h4 svg {
    margin-right: 8px;
    margin-bottom: -2.5px;
  }

  dt {
    float: left;
    font-weight: bold;
    clear: both;
    margin-bottom: 10px;
  }

  dt.target {
    width: 100%;
  }

  dd {
    float: right;
    text-align: right;
  }

  br.clearFix {
    clear: both;
  }

  .risk-indicator-container {
    clear: both;
    width: 100%;

    .stock-number {
      text-align: left;
    }

    .blur,
    .risk-indicator {
      width: 100px;
      height: 100px;
    }

    .blur {
      border-radius: 50px;
    }
  }

  button.copy {
    position: relative;
    transition: all $timer-standard ease;
    transition-property: background-color, color;

    svg {
      display: none;
    }

    &.yay {
      background-color: $color-green;
      color: $color-green;

      svg {
        display: block;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        margin: 0;
      }
    }
  }

  table {
    width: 100%;
    padding: 0 10px;
  }

  th {
    text-align: left;
  }

  th + th,
  td + td {
    text-align: right;
  }

  .email-reminders {
    margin-top: 24px;
  }

  .signer-names {
    padding: 0;
    margin: 0;
  }

  .card.declaration {
    margin-top: 38px;
  }

  .disclaimer {
    padding: 20px 12px;

    button {
      background: none !important;
      border: none;
      padding: 0 !important;
      text-decoration: underline;
      cursor: pointer;
      color: $color-primary-enabled;
    }

    button:hover {
      color: $color-primary-hover;
    }

    button:active,
    button:focus {
      color: $color-primary-focus;
    }
  }
}
