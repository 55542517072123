.company-signup-users {
  display: flex;
  flex-direction: column;
  justify-content: center;

  a.lysa-ui-button {
    box-sizing: border-box;

    svg {
      margin-top: -2px;
      margin-left: 12px;
    }
  }

  .lysa-story-header {
    margin-bottom: 0;
  }

  .user-list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: transparent;

    > span {
      font-weight: bold;
    }
  }
}
