@import "~@lysaab/ui-2/components/styling/_variables";

.company-signup-creating {
  .lysa-ui-spinner {
    max-width: 200px;
  }

  p {
    text-align: center;
  }
}
